import styles from "./styles.module.scss"
import Link from "next/link"
import router from "next/router"
import { useSubCore } from "@/hooks/useSubCore"
import { useTranslation } from "next-i18next"

export const NavigationHomeMobile = () => 
{
    const { t }                                                   = useTranslation()
    const { setSelectedSport, setSelectedLeague, setReloadGames } = useSubCore()

    return (
        <div className={styles.navigationHomeMobile}>
            {/* {Array.from({ length: 13 }).map((index: any) => (
                <div className={styles.cardNavigation} key={index}>
                    <img src="/assets/img/sports_yellow.png" alt="" />
                    <div className={styles.divisor} />
                    <p>스포츠</p>
                </div> 
            ))} */}

            <Link href='/sports'>
                <div
                    className={styles.cardNavigation}
                    onClick={() => 
                    {
                        setSelectedSport(null)
                        setSelectedLeague(null)
                        setReloadGames(true)
                    }}
                >
                    <img src='/assets/img/sports_yellow.png' alt='' />
                    <div className={styles.divisor} />
                    <p>{t("Esportes")}</p>
                </div>
            </Link>

            <Link
                href='/liveSports'
                onClick={() => 
                {
                    setSelectedSport(null)
                    setSelectedLeague(null)
                }}
            >
                <div className={styles.cardNavigation}>
                    <img src='/assets/img/sports_yellow.png' alt='' />
                    <div className={styles.divisor} />
                    <p>{t("Live Sports")}</p>
                </div>
            </Link>
            <Link href='/casino'>
                <div className={styles.cardNavigation}>
                    <img src='/assets/img/casino_yellow.png' alt='' />
                    <div className={styles.divisor} />
                    <p>{t("Cassino")}</p>
                </div>
            </Link>

            <Link href='/mini/mgm/baccarat/1'>
                <div className={styles.cardNavigation}>
                    <img src='/assets/img/mini-game_yellow.png' alt='' />
                    <div className={styles.divisor} />
                    <p>{t("Mini Game")}</p>
                </div>
            </Link>

            {/* <div
        className={styles.cardNavigation}
        onClick={() => router.push('/deposit?tabIndex=0')}
      >
        <img src='/assets/img/deposito_yellow.png' alt='' />
        <div className={styles.divisor} />
        <p>{t('Depósito')}</p>
      </div>

      <div
        className={styles.cardNavigation}
        onClick={() => router.push('/deposit?tabIndex=1')}
      >
        <img src='/assets/img/payout_yellow.png' alt='' />
        <div className={styles.divisor} />
        <p>{t('Saque')}</p>
      </div> */}

            {/* <div
        className={styles.cardNavigation}
        onClick={() => router.push('/deposit?tabIndex=2')}
      >
        <img src='/assets/img/movimentacao_yellow.png' alt='' />
        <div className={styles.divisor} />
        <p>{t('Movimentação')}</p>
      </div>

      <div
        className={styles.cardNavigation}
        onClick={() => router.push('/deposit?tabIndex=4')}
      >
        <img src='/assets/img/customer_yellow.png' alt='' />
        <div className={styles.divisor} />
        <p>{t('Perguntas')}</p>
      </div> */}

            <div
                className={styles.cardNavigation}
                onClick={() => router.push("/deposit?tabIndex=5")}
            >
                <img src='/assets/img/note_yellow.png' alt='' />
                <div className={styles.divisor} />
                <p>{t("Avisos")}</p>
            </div>

            <Link href='/event'>
                <div className={styles.cardNavigation}>
                    <img src='/assets/img/event_yellow.png' alt='' />
                    <div className={styles.divisor} />
                    <p>{t("Evento")}</p>
                </div>
            </Link>

            <div
                className={styles.cardNavigation}
                onClick={() => router.push("/deposit?tabIndex=3")}
            >
                <img src='/assets/img/coupon_yellow.png' alt='' />
                <div className={styles.divisor} />
                <p>{t("Cupom / Pontos")}</p>
            </div>

            {/* <div
        className={styles.cardNavigation}
        onClick={() => {
          router.push('/')
        }}
      >
        <img src='/assets/img/attendance_yellow.png' alt='' />
        <div className={styles.divisor} />
        <p>{t('CheckIn')}</p>
      </div> */}

            {/* <Link href='/friend'>
        <div className={styles.cardNavigation}>
          <img src='/assets/img/friend_yellow.png' alt='' />
          <div className={styles.divisor} />
          <p>{t('INDICAÇÃO')}</p>
        </div>
      </Link> */}

            <div
                className={styles.cardNavigation}
                onClick={() => router.push("/deposit?tabIndex=8")}
            >
                <img src='/assets/img/notice_yellow.png' alt='' />
                <div className={styles.divisor} />
                <p>{t("Avisos / Regras")}</p>
            </div>

            <div
                className={styles.cardNavigation}
                onClick={() => router.push("/deposit?tabIndex=6")}
            >
                <img src='/assets/img/betting-history_yellow.png' alt='' />
                <div className={styles.divisor} />
                <p>{t("Histórico de Apostas")}</p>
            </div>
        </div>
    )
}
