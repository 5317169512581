import styles from "./styles.module.scss"
import {
    EVENT_SETTING,
    HOME_PAGE_SETTING,
    POPUP_SETTING,
    POST_SETTING,
    REVALIDATE_SETTING,
} from "@/setting/setting"
import { GetStaticProps, NextPage } from "next"
import {
    collectionDocument,
    getDocument,
} from "@/core-nextv3/document/document.api"
import { HomeTwo } from "../HomeTwo"
import { calls, revalidateResults } from "@/core-nextv3/util/call.api"
import { useContext, useEffect } from "react"
import { useSubCore } from "@/hooks/useSubCore"
import { useResponsive } from "@/core-nextv3/util/useResponsive"
import withHeader from "../utils/withHeader"

const HomePage: NextPage = ({ posts, events, homePage, locale, popups }: any) => 
{
    // console.warn('POSTS', posts, events, homePage, popups)
    // console.warn(test)
    const { isClient }  = useResponsive();
    const { setPopups } = useSubCore()

    useEffect(() => 
    {
        setPopups(popups)
    }, [])

    if (!isClient) 
    {
        return <></>
    }

    return (
        <main className={styles.homePage}>
            <div className={styles.content}>
                <HomeTwo posts={posts} events={events} homePage={homePage} />
            </div>
        </main>
    )
}

export const getStaticProps: GetStaticProps = ({ locale }: any) => withHeader(async (props: any) => 
{
    // precisa de testes
    if (props?.revalidateWithHeader) 
    {
        return {
            notFound   : true,
            revalidate : true,
        }
    }

    const [
        resultPosts,
        resultEvents,
        resultHomePage,
        resultPopups
    ] =
      await calls(
          collectionDocument(POST_SETTING.merge({ perPage : 50 })),
          collectionDocument(EVENT_SETTING.merge({ perPage : 50 })),
          getDocument(HOME_PAGE_SETTING),
          collectionDocument(POPUP_SETTING.merge({ perPage : 50 }))
      )

    const result = revalidateResults([
        resultPosts,
        resultEvents,
        resultHomePage,
        resultPopups,
    ])

    if (result.revalidate) 
    {
        return result
    }

    return {
        props : {
            locale   : locale,
            posts    : resultPosts?.collection || [],
            events   : resultEvents?.collection || [],
            homePage : resultHomePage?.data || [],
            popups   : resultPopups?.collection || [],
        },
        revalidate : REVALIDATE_SETTING,
    }
})

export { getStaticProps as GetStaticProps, HomePage }