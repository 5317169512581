import { useRouter } from "next/router"
import { EventHome } from "../HomeComponents/EventHome"
import { NoticeHome } from "../HomeComponents/NoticeHome"
import styles from "./styles.module.scss"
import { useCore } from "@/core-nextv3/core/core"
import { useResponsive } from "@/core-nextv3/util/useResponsive"
import { NavigationHomeMobile } from "../HomeComponents/NavigationHomeMobile"
import { useContext } from "react"
import { AuthContext } from "../context/AuthContext"
import { useTranslation } from "next-i18next"
import router from "next/router"
import { BannerSlider } from "../HomeComponents/BannerSlider"
import { useSubCore } from "@/hooks/useSubCore"
import redirectTo from "@/bet-nextv1/utils/redirectTo"

export const HomeTwo: React.FC<any> = ({ posts, events, homePage }:any) => 
{
    // console.log("HomeTwo", homePage)
    const { push: route }       = useRouter()
    const { isDesktop }         = useResponsive()
    const { t }                 = useTranslation()
    const { setOpenLoginModal } = useSubCore()
    const { isClient }          = useResponsive();

    const { user } = useContext<any>(AuthContext)

    if (!isClient) 
    {
        return <></>
    }

    return (
        <div className={styles.homeTwo}>
            {!isDesktop && (
                <div className={styles.informationsMonney}>
                    <div className={styles.inputInformation}>
                        <div className={styles.inputItem}>
                            <img src='/assets/img/sportscash.png' alt='' />
                            <label>{t("Dinheiro Esporte")}</label>
                        </div>
                        <span className={styles.value}>
                            {user?.sport
                                ? new Intl.NumberFormat("en").format(user?.sport)
                                : 0}{" "}
                            {t("Won")}
                        </span>
                    </div>

                    <div className={styles.inputInformation}>
                        <div className={styles.inputItem}>
                            <img src='/assets/img/casinocash.png' alt='' />
                            <label>{t("Dinheiro do Cassino")}</label>
                        </div>
                        <span className={styles.value}>
                            {user?.casino
                                ? new Intl.NumberFormat("en").format(user?.casino)
                                : 0}{" "}
                            {t("Won")}
                        </span>
                    </div>
                </div>
            )}

            {/* {homePage?.bannersHome?.map((imageBanner: any) => (
        <div key={imageBanner?.id}>
          {isDesktop && <img alt='' src={imageBanner?.desktop?._url} />}
          {!isDesktop && <img alt='' src={imageBanner?.mobileImage?._url} />}
        </div>
      ))} */}
            <BannerSlider images={homePage?.bannersHome} />

            <div className={styles.content}>
                {isDesktop && (
                    <div className={styles.informations}>
                        <NoticeHome posts={posts} />
                        <EventHome events={events} />
                    </div>
                )}

                {!isDesktop && (
                    <div className={styles.navigationMobile}>
                        <div className={styles.upperLinks}>
                            <div
                                className={styles.upperLinksButton}
                                onClick={() => redirectTo("/deposit?tabIndex=0", user, router, setOpenLoginModal)}
                            >
                                <img src='/assets/img/deposito.png' alt='' />
                                <p>{t("Depósito")}</p>
                            </div>

                            <div
                                className={styles.upperLinksButton}
                                onClick={() => redirectTo("/deposit?tabIndex=1", user, router, setOpenLoginModal)}
                            >
                                <img src='/assets/img/payout.png' alt='' />
                                <p>{t("Saque")}</p>
                            </div>

                            <div
                                className={styles.upperLinksButton}
                                onClick={() => redirectTo("/deposit?tabIndex=2", user, router, setOpenLoginModal)}
                            >
                                <img src='/assets/img/movimentacao.png' alt='' />
                                <p>{t("Movimentação")}</p>
                            </div>

                            <div
                                className={styles.upperLinksButton}
                                onClick={() => redirectTo("/deposit?tabIndex=4", user, router, setOpenLoginModal)}
                            >
                                <img src='/assets/img/customer.png' alt='' />
                                <p>{t("Perguntas")}</p>
                            </div>
                        </div>
                        <NavigationHomeMobile />
                    </div>
                )}

                <div className={styles.bannersHome}>
                    {homePage?.bannersGames?.map((miniBanners: any, index: any) => (
                        <div className={styles.bannerItem} key={index}>
                            {isDesktop && (
                            // eslint-disable-next-line @next/next/no-img-element
                                <img
                                    className={styles.classBannerImg}
                                    alt=''
                                    src={miniBanners?.desktop?._url}
                                    onClick={() => redirectTo(miniBanners?.url, user, router, setOpenLoginModal)}
                                />
                            )}

                            {!isDesktop && (
                                <img
                                    className={styles.overLayImg}
                                    alt=''
                                    src={miniBanners?.mobile?._url}
                                    onClick={() => redirectTo(miniBanners?.url, user, router, setOpenLoginModal)}
                                />
                            )}
                            <div className={styles.bannerOverlay}>
                                <div className={styles.overLayImg}><img src={"assets/img/logo.png"}></img></div>
                                <span className={styles.overLayText}>스포츠</span>
                                <span className={styles.overLayButton}>배팅하러 가기</span>
                            </div>
                        </div>
                    ))}
                </div>

                <div className={styles.sponsors}>
                    {homePage?.imageSponsors?.map((logo: any, index: any) => (
                        <img alt='' key={index} src={logo?.image?._url} />
                    ))}
                </div>

                {!isDesktop && (
                    <div className={styles.bannersLinks}>
                        {homePage?.bannersLinks?.map((banner: any) => (
                            <img
                                alt=''
                                key={banner?.id}
                                src={banner?.imageBanner?._url}
                                onClick={() => window.open(banner?.link)}
                            />
                        ))}
                    </div>
                )}
            </div>
        </div>
    )
}
